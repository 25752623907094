import { toast } from 'react-toastify';
import { table_config } from './helper';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GmModal from 'app/shared/modal/modal';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import WebDataTable from 'app/shared/datatable/web/datatable';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import ImportContact from 'app/member/modules/contacts/import/import';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';

import {
  addManyContactsToStore,
  addContactSearchResultsToStore,
  removeManyContactsFromStore,
  removeOneContactFromStore,
  updateContactInStore
} from 'store/actions/contact';
import { setPageTitle } from 'store/actions/header';
import AddContactToAudience from '../add-to-audience/add-to-audience';
import { Button } from 'app/shared/button';
import { Spacer } from 'app/layouts/generic';
import styles from './list.module.css';
import { ContactStatBar } from './statbar';
import { CreateContactModal } from '../create';
import useScreenSize from 'hooks/size';
import { useContactService } from 'hooks/users/contact';
import { PermissionsContext } from 'contexts/permissions';

const ListContacts = () => {
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const contacts_in_store = useSelector((state) => state.contacts);
  const { isMobile } = useScreenSize();
  const { fetchContacts, searchContact, updateContact, removeContact, removeContacts } =
    useContactService();
  const { permissions } = useContext(PermissionsContext);

  const dispatch = useDispatch();
  const history = useNavigate();

  const [items, setItems] = useState([]);
  const [selected_contacts, setSelectedContacts] = useState([]);
  const [unselect_items, setUnSelectItems] = useState(true);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading_data, setLoadingData] = useState(false);
  const [contact_count, setContactCount] = useState(0);

  const [show_creation_modal, setShowCreationModal] = useState(false);
  const [show_upload_modal, setShowUploadModal] = useState(false);
  const [show_contact_link_modal, setShowContactLinkModal] = useState(false);
  const [show_confirmation, setShowConfirmation] = useState(false);
  const [contact_to_delete, setContactToDelete] = useState(0);

  const [bulk_contact_to_delete, setBulkContactsToDelete] = useState([]);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Contacts', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItems(Object.values(contacts_in_store));
  }, [contacts_in_store]);

  const deleteContact = async (id) => {
    await removeContact(id);
    dispatch(removeOneContactFromStore(id));
    toast.success('contact deleted successfully.');
  };

  const deleteBulkContact = async (ids) => {
    const data = {
      options: {
        id: ids.toString()
      }
    };
    await removeContacts({ data });

    dispatch(removeManyContactsFromStore(ids));
    toast.success('contacts deleted successfully.');
  };

  const handleConfirmation = (permitted) => {
    if (permitted) {
      if (bulk_contact_to_delete.length > 0) deleteBulkContact(bulk_contact_to_delete);
      else deleteContact(contact_to_delete);
    }

    setContactToDelete(0);
    setBulkContactsToDelete([]);
    setShowConfirmation(false);
  };

  const updateContactStatus = async (data) => {
    const payload = await updateContact(data.id, { data: { is_valid: true } });
    if (payload) {
      toast.success('Contact updated successfully.');
      dispatch(updateContactInStore({ ...data, is_valid: true }));
    }
  };

  const handleDatatableAction = async (action) => {
    const { name, type, data } = action;
    if (type.toLowerCase() === 'bulk') {
      switch (name.toLowerCase()) {
        case 'add_to_audience':
          setSelectedContacts(data);
          setShowContactLinkModal(true);
          break;
        case 'delete':
          setBulkContactsToDelete(data.map((item) => item.id));
          setShowConfirmation(true);
          break;
        default:
      }
    }

    if (type.toLowerCase() === 'single') {
      switch (name.toLowerCase()) {
        case 'add_to_audience':
          setSelectedContacts([data]);
          setShowContactLinkModal(true);
          break;
        case 'delete':
          setContactToDelete(data.id);
          setShowConfirmation(true);
          break;
        case 'mark_as_valid':
          updateContactStatus(data);
          break;
        default:
      }
    }
  };

  const handleItemClick = (data) => {
    history(`/contacts/${data.id}`);
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoadingData(true);
      const { contacts, size, error } = await fetchContacts({
        query_string: `sort_by=-id&page=${page}&population=${population}`
      });
      if (error) {
        toast.error('Unable to fetch contacts at this time.');
        return;
      }
      setContactCount(size);
      dispatch(addManyContactsToStore(contacts));
    } catch (e) {
      dispatch(addManyContactsToStore([]));
    } finally {
      setLoadingData(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoadingData(true);

      const { contacts, size } = await searchContact(keys, keyword, {
        query_string: `sort_by=-created_on&page=${page}&population=${population}`
      });

      setContactCount(size);
      if (page === 0) return dispatch(addContactSearchResultsToStore(contacts));
      dispatch(addManyContactsToStore(contacts));
    } catch (e) {
      dispatch(addManyContactsToStore([]));
    } finally {
      setLoadingData(false);
    }
  };

  const table_actions = (
    <>
      <Button
        icon_name="add"
        text="Contact"
        onClick={() => setShowCreationModal(true)}
        disabled={!permissions['contact:create']}
      />
      <Button
        icon_name="upload"
        text="Import"
        onClick={() => setShowUploadModal(true)}
        disabled={!permissions['contact:create']}
      />
    </>
  );

  return (
    <PageContentWrapper>
      {!isMobile && (
        <>
          <ContactStatBar />
          <Spacer multiple={4} />
        </>
      )}
      {is_mobile_view ? (
        <MobileDatatable
          config={{
            ...table_config(permissions),
            is_search_mode,
            items,
            total_count: contact_count
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
          showHeader
        />
      ) : (
        <div>
          <WebDataTable
            config={{
              ...table_config(permissions),
              is_search_mode,
              items: items.sort((a, b) => b.id - a.id),
              total_count: contact_count
            }}
            action={handleDatatableAction}
            onClick={handleItemClick}
            checkbox
            loading_data={loading_data}
            table_actions={table_actions}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            resetBulkSelection={unselect_items}
          />
        </div>
      )}
      <GmModal
        bodyClassName={styles.importsModal}
        show_title={true}
        title="Add Contacts to Audience"
        show_modal={show_contact_link_modal}
        onClose={() => setShowContactLinkModal(false)}
      >
        <AddContactToAudience
          selected_contacts={selected_contacts}
          show_modal={show_contact_link_modal}
          handleBulkUnselection={() => setUnSelectItems(!unselect_items)}
          onClose={() => setShowContactLinkModal(false)}
        />
      </GmModal>
      <GmModal
        bodyClassName={styles.importsModal}
        title="Create Contact"
        show_title={true}
        show_modal={show_creation_modal}
        onClose={() => setShowCreationModal(false)}
      >
        <CreateContactModal onClose={() => setShowCreationModal(false)} />
      </GmModal>
      <GmModal
        bodyClassName={styles.importsModal}
        title="Import Contacts"
        show_title={true}
        show_modal={show_upload_modal}
        onClose={() => setShowUploadModal(false)}
      >
        <ImportContact onDataRequest={handleDataRequest} />
      </GmModal>

      <ConfirmationDialog
        title="Delete contact"
        message="Are you sure you want to delete this contact?"
        callback={handleConfirmation}
        is_open={show_confirmation}
      />
    </PageContentWrapper>
  );
};

export default ListContacts;
